var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useWindowResolution } from 'shared/lib/hooks/useWindowResolution';
import { BlockLoader } from 'shared/ui/BlockLoader';
import { Button, ButtonSize, ButtonTheme } from 'shared/ui/Button';
import StatisticTable from 'shared/ui/Statistic/Table';
import { Title } from 'shared/ui/Title';
import { Wrapper } from 'shared/ui/Wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getGourseDetailStatisticData } from './model/services/getCourseDetailStatistic';
import { getCourseDetailStatisticDataSelector } from './model/selectors/getCourseDetailStatistic';
import cls from './CourseDetailPage.module.scss';
var COLUMNS = {
    pod: '№',
    name: 'Название урока',
    attempted: 'Начавших урок',
    finished: 'Завершивших',
};
export default function CourseDetailPage() {
    var courseId = useParams().courseId;
    var _a = useState(null), courseLessons = _a[0], setCourseLessons = _a[1];
    var searchParams = useSearchParams()[0];
    var navigate = useNavigate();
    var courseName = searchParams.get('name');
    var windowResolution = useWindowResolution();
    var dispatch = useDispatch();
    var courseDetailDataFromStore = useSelector(getCourseDetailStatisticDataSelector);
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit;
    // Загрузка статистики сервера и обновление стора
    useEffect(function () {
        dispatch(getGourseDetailStatisticData({ courseId: +courseId }));
    }, []);
    // Как только данные загрузились => re-render
    useEffect(function () {
        setCourseLessons(courseDetailDataFromStore);
    }, [courseDetailDataFromStore]);
    var submitForm = function (formFields) {
        var copiedCourseDetail = JSON.parse(JSON.stringify(courseDetailDataFromStore));
        var filteredCourseDetail = Object.entries(copiedCourseDetail).filter(function (_a) {
            var courseKey = _a[0], course = _a[1];
            return formFields
                .search && course.name.includes(formFields.search);
        });
        if (filteredCourseDetail.length === 0) {
            setCourseLessons(courseDetailDataFromStore);
            return;
        }
        setCourseLessons(Object.fromEntries(filteredCourseDetail));
    };
    return (_jsxs(Wrapper, { className: cls.wrapper, children: [_jsxs(Title, { className: cls.title, children: ["\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430 \u043F\u043E \u043A\u0443\u0440\u0441\u0443 ", _jsx("br", {}), " ", courseName !== null ? "'".concat(courseName, "'") : ''] }), _jsx("form", { onChange: handleSubmit(submitForm), children: _jsx("input", __assign({ className: cls.formElement }, register('search'), { placeholder: "\u041F\u043E\u0438\u0441\u043A \u043F\u043E \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u044E" })) }), courseLessons === null ? _jsx(BlockLoader, {})
                : (_jsx(StatisticTable, { columns: COLUMNS, children: Object.entries(courseLessons).map(function (_a) {
                        var id = _a[0], _b = _a[1], name = _b.name, attempted = _b.attempted, finished_by = _b.finished_by;
                        return (windowResolution.width > 766 ? (_jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { children: id }), _jsx("td", { children: name }), _jsx("td", { children: attempted }), _jsx("td", { children: Number(finished_by === null || finished_by === void 0 ? void 0 : finished_by.length) }), _jsx("td", { className: "table__buttons", children: _jsx(Button, { theme: ButtonTheme.PRIMARY, size: ButtonSize.S, onClick: function () {
                                                navigate("/statistic/lesson/".concat(id, "?name=").concat(encodeURIComponent(name)));
                                            }, children: "\u0423\u0440\u043E\u043A" }) }), _jsx("td", { children: _jsx(Button, { theme: ButtonTheme.PRIMARY, size: ButtonSize.S, onClick: function () {
                                                navigate("/statistic/lesson/".concat(id, "/tests?email=").concat(encodeURIComponent(name)));
                                            }, children: "\u0422\u0435\u0441\u0442\u044B" }) })] }) }, id)) : (_jsxs("div", { className: cls.userCard, children: [_jsxs("div", { className: cls.userCard__content, children: [_jsxs("p", { children: [_jsx("b", { children: "\u2116: " }), id] }), _jsx("p", { className: cls.userCard__content__name, children: name }), _jsxs("p", { children: [_jsx("span", { style: { fontWeight: 500 }, children: "\u041D\u0430\u0447\u0430\u0432\u0448\u0438\u0445 \u0443\u0440\u043E\u043A:" }), " ", attempted] }), _jsxs("p", { children: [_jsx("span", { style: { fontWeight: 500 }, children: "\u0417\u0430\u0432\u0435\u0440\u0448\u0438\u0432\u0448\u0438\u0445:" }), " ", Number(finished_by === null || finished_by === void 0 ? void 0 : finished_by.length)] })] }), _jsxs("div", { className: cls.userCard__buttons, children: [_jsx(Button, { theme: ButtonTheme.PRIMARY, size: ButtonSize.S, onClick: function () {
                                                navigate("/statistic/lesson/".concat(id, "?name=").concat(encodeURIComponent(name)));
                                            }, children: "\u0423\u0440\u043E\u043A" }), _jsx(Button, { theme: ButtonTheme.PRIMARY, size: ButtonSize.S, onClick: function () {
                                                navigate("/statistic/lesson/".concat(id, "/tests?email=").concat(encodeURIComponent(name)));
                                            }, children: "\u0422\u0435\u0441\u0442\u044B" })] })] }, id)));
                    }) }))] }));
}
