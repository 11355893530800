var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $api } from 'shared/api/api';
import { BlockLoader } from 'shared/ui/BlockLoader';
import { Button } from 'shared/ui/Button';
import StatisticTable from 'shared/ui/Statistic/Table';
import { Title } from 'shared/ui/Title';
import { Wrapper } from 'shared/ui/Wrapper';
import Eye from 'shared/assets/icons/eye.svg';
import Export from 'shared/assets/icons/export.svg';
import { JsonToFormData } from 'shared/lib/JsonToFormData';
import { useWindowResolution } from 'shared/lib/hooks/useWindowResolution';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getCourseStatistic } from './model/services/getCourseStatistic';
import { getCourseStatisticData } from './model/selectors/getCourseStatistic';
import cls from './CourseListPage.module.scss';
var COLUMNS = {
    pos: '№',
    name: 'Название',
    lessonCount: 'Количество уроков',
};
export default function CourseListPage() {
    var navigate = useNavigate();
    var _a = useState(null), courses = _a[0], setCourses = _a[1];
    var windowResolution = useWindowResolution();
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit;
    var dispatch = useDispatch();
    var courseStatisticFromStore = useSelector(getCourseStatisticData);
    // Загрузка статистики сервера и обновление стора
    useEffect(function () {
        dispatch(getCourseStatistic());
    }, []);
    // Как только данные загрузились => re-render
    useEffect(function () {
        setCourses(courseStatisticFromStore);
    }, [courseStatisticFromStore]);
    var submitForm = function (formFields) {
        var copiedCoursesStatistic = JSON.parse(JSON.stringify(courseStatisticFromStore));
        var filteredStatistic = copiedCoursesStatistic.filter(function (course) { return formFields
            .search && course.name.includes(formFields.search); });
        if (filteredStatistic.length === 0) {
            setCourses(courseStatisticFromStore);
            return;
        }
        setCourses(filteredStatistic);
    };
    return (_jsxs(Wrapper, { children: [_jsx(Title, { className: cls.title, children: "\u0421\u0442\u0430\u0442\u0438c\u0442\u0438\u043A\u0430 \u043F\u043E \u043A\u0443\u0440\u0441\u0430\u043C" }), _jsx("form", { onChange: handleSubmit(submitForm), children: _jsx("input", __assign({ className: cls.formElement }, register('search'), { placeholder: "\u041F\u043E\u0438\u0441\u043A \u043F\u043E \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u044E" })) }), courses === null ? _jsx(BlockLoader, {})
                : (_jsx(StatisticTable, { columns: COLUMNS, children: courses.map(function (_a) {
                        var id = _a.id, name = _a.name, lesson_count = _a.lesson_count;
                        return (windowResolution.width > 766 ? (_jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { children: id }), _jsx("td", { children: name }), _jsx("td", { children: lesson_count }), _jsxs("td", { className: "table__buttons", children: [_jsx(Button, { icon: _jsx(Eye, {}), onClick: function () {
                                                    navigate("/statistic/".concat(id, "?name=").concat(encodeURIComponent(name)));
                                                } }), _jsx(Button, { icon: _jsx(Export, {}), onClick: function () {
                                                    var formData = new FormData();
                                                    JsonToFormData(formData, {
                                                        exportType: 'Xlsx',
                                                        exportull_w0: 1,
                                                        export_columns: '["0","1","2","3"]',
                                                        column_selector_enabled: 1,
                                                    });
                                                    $api
                                                        .post('/ext-course/frontend/manage/statistic/course?export=true', { courseId: id })
                                                        .then(function (response) {
                                                        if (response.status === 200) {
                                                            var blob = new Blob(['\uFEFF' + response.data.export], { type: 'text/csv;charset=utf-8' });
                                                            console.log(URL.createObjectURL(blob));
                                                            var url = window.URL.createObjectURL(blob);
                                                            var downloadLink = document.createElement('a');
                                                            // debugger
                                                            downloadLink.href = url;
                                                            downloadLink.download = 'exposrt.csv';
                                                            document.body.appendChild(downloadLink);
                                                            downloadLink.click();
                                                            document.body.removeChild(downloadLink);
                                                            window.URL.revokeObjectURL(url);
                                                            return;
                                                        }
                                                        throw new Error('Failed to fetch statistic');
                                                    });
                                                } })] })] }, id) })) : (_jsxs("div", { className: cls.userCard, children: [_jsxs("div", { className: cls.userCard__content, children: [_jsxs("p", { children: [_jsx("b", { children: "\u2116: " }), id] }), _jsx("p", { children: name }), _jsxs("p", { children: ["\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0443\u0440\u043E\u043A\u043E\u0432: ", lesson_count] })] }), _jsxs("div", { className: cls.userCard__buttons, children: [_jsx(Button, { icon: _jsx(Eye, {}), onClick: function () {
                                                navigate("/statistic/".concat(id, "?name=").concat(encodeURIComponent(name)));
                                            } }), _jsx(Button, { icon: _jsx(Export, {}), onClick: function () {
                                                var formData = new FormData();
                                                JsonToFormData(formData, {
                                                    exportType: 'Xlsx',
                                                    exportull_w0: 1,
                                                    export_columns: '["0","1","2","3"]',
                                                    column_selector_enabled: 1,
                                                });
                                                $api
                                                    .post('/ext-course/frontend/manage/statistic/course?export=true', { courseId: id })
                                                    .then(function (response) {
                                                    if (response.status === 200) {
                                                        var blob = new Blob(['\uFEFF' + response.data.export], { type: 'text/csv;charset=utf-8' });
                                                        console.log(URL.createObjectURL(blob));
                                                        var url = window.URL.createObjectURL(blob);
                                                        var downloadLink = document.createElement('a');
                                                        // debugger
                                                        downloadLink.href = url;
                                                        downloadLink.download = 'exposrt.csv';
                                                        document.body.appendChild(downloadLink);
                                                        downloadLink.click();
                                                        document.body.removeChild(downloadLink);
                                                        window.URL.revokeObjectURL(url);
                                                        return;
                                                    }
                                                    throw new Error('Failed to fetch statistic');
                                                });
                                            } })] })] }, id)));
                    }) }))] }));
}
