import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from 'shared/lib/classNames/classNames';
import { AppLink, AppLinkSize, AppLinkTheme } from 'shared/ui/AppLink/AppLink';
// import { Button } from 'shared/ui/Button';
import User from 'shared/assets/icons/user.svg';
import Quest from 'shared/assets/icons/question.svg';
// import Chat from 'shared/assets/icons/chat.svg';
import { useSelector } from 'react-redux';
// import { supportActions } from 'entities/Support/model/slice/supportSlice';
// import { chatActions } from 'entities/Chat/model/slice/chatSlice';
import { DAccess } from 'shared/config/dictionaries/userDictionaries';
import { getUserAccess } from 'entities/User/model/selectors/getUser';
import cls from './Menu.module.scss';
export var Menu = function (_a) {
    var className = _a.className;
    var userAccess = useSelector(getUserAccess);
    // const toggleSupportForm = () => {
    //     dispatch(supportActions.toggleSupportForm());
    // };
    // const toggleChatModal = () => {
    //     dispatch(chatActions.toggleChatModal());
    // };
    return (_jsx("div", { children: _jsxs("ul", { className: classNames(cls.Menu, {}, [className]), children: [(userAccess === DAccess.canEditAdmin)
                    && (_jsx("li", { children: _jsx(AppLink, { to: "/admin", theme: AppLinkTheme.PRIMARY, size: AppLinkSize.XS, target: "_blank", rel: "noopener noreferrer", children: "\u0410\u0434\u043C\u0438\u043D \u043F\u0430\u043D\u0435\u043B\u044C" }) })), _jsx("li", { children: _jsx(AppLink, { title: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C", to: "/profile", theme: AppLinkTheme.NAVIGATION, icon: _jsx(User, {}) }) }), _jsx("li", { children: _jsx(AppLink, { title: "\u0422\u0435\u0445\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0430", to: "/support", icon: _jsx(Quest, {}) }) })] }) }));
};
