import { configureStore } from '@reduxjs/toolkit';
import { navigationReducer } from 'features/Navigation';
import { userReducer } from 'entities/User';
import { loginReducer } from 'features/AuthByEmail';
import { $api } from 'shared/api/api';
import { testReducer } from 'entities/Test/model/slice/testSlice';
import { chatReducer } from 'entities/Chat/model/slice/chatSlice';
import { courseReducer } from 'entities/Course/model/slice/courseSlice';
import { supportReducer } from 'entities/Support/model/slice/supportSlice';
import { lessonReducer } from 'entities/Lesson/model/slice/lessonSlice';
import { lessonStatisticReducer } from 'pages/Statistic/Courses/LessonPage/model/slice/LessonStatisticSlice';
import { questionsReducer } from 'features/Questions/model/slice/questionsSlice';
import { baseReducer } from 'entities/Base/model/slice/baseSlice';
import { calendarReducer } from 'features/CalendarEventForm/model/slice/calendalSlice';
import { groupReducer } from 'entities/Group/model/slice/groupSlice';
import { votingReducer } from 'entities/Voting/model/slice/votingSlice';
import { taskReducer } from 'entities/Task/model/slice/taskSlice';
import { teamReducer } from 'entities/Team/model/slice/teamSlice';
import { projectReducer } from 'entities/Project/model/slice/projectSlice';
import { lessonEditReducer } from 'features/LessonEdit/model/slice/lessonEditSlice';
import { glossaryEditReducer } from 'features/GlossaryEdit/slice/glossaryEditSlice';
import { glossaryReducer } from 'entities/Glossary/slice/glossarySlice';
import { taskEditReducer } from 'features/TaskEdit/slice/taskEditSlice';
import { votingEditReducer } from 'features/VotingEdit/slice/votingSlice';
import { signatoryReducer } from 'entities/Signatory';
import { courseStatistcReducer } from 'pages/Statistic/Courses/CourseListPage/model/slice/CourseListSlice';
import { courseDetailReducer } from 'pages/Statistic/Courses/CourseDetailPage/model/slice/CourseDetailSlice';
import { lineChartReducer } from 'features/LineChart/model/slice/LineChartSlice';
export function createReduxStore(initialState, navigate) {
    var extraArg = {
        api: $api,
        navigate: navigate,
    };
    var store = configureStore({
        reducer: {
            navigation: navigationReducer,
            user: userReducer,
            login: loginReducer,
            questions: questionsReducer,
            course: courseReducer,
            lesson: lessonReducer,
            lessonStatistic: lessonStatisticReducer,
            courseStatistic: courseStatistcReducer,
            courseDetailStatistic: courseDetailReducer,
            glossary: glossaryReducer,
            test: testReducer,
            support: supportReducer,
            chat: chatReducer,
            calendar: calendarReducer,
            base: baseReducer,
            lineChart: lineChartReducer,
            group: groupReducer,
            voting: votingReducer,
            task: taskReducer,
            team: teamReducer,
            project: projectReducer,
            lessonEdit: lessonEditReducer,
            glossaryEdit: glossaryEditReducer,
            taskEdit: taskEditReducer,
            votingEdit: votingEditReducer,
            signatory: signatoryReducer,
        },
        devTools: __IS_DEV__,
        preloadedState: initialState,
        middleware: function (getDefaultMiddleware) { return getDefaultMiddleware({
            thunk: {
                extraArgument: extraArg,
            },
        }); },
    });
    return store;
}
